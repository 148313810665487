































































































































































































































































































import mixins from 'vue-typed-mixins'
import FlexibleBookingMonthlyCalendar from '@/builder/sections/section/shared/mixins/FlexibleBookingMonthlyCalendar'
import EmployeePicker from '@/builder/sections/section/shared/components/EmployeePicker.vue'
import { Moment } from 'moment'
import numberToTime from '@/calendesk/tools/numberToTime'
import timeToNumber from '@/calendesk/tools/timeToNumber'
import LocationInput from '@/builder/sections/shared/LocationInput.vue'
import TimeZoneInput from '@/builder/sections/shared/TimeZoneInput.vue'

export default mixins(FlexibleBookingMonthlyCalendar).extend({
  name: 'FlexibleBooking3',
  components: { TimeZoneInput, LocationInput, EmployeePicker },
  computed: {
    startDate (): Moment {
      return this.$moment(this.selectedDate, this.$helpers.dateFormat).startOf('week')
    },
    endDate (): Moment {
      return this.$moment(this.selectedDate, this.$helpers.dateFormat).endOf('week')
    },
    getAllRangeDates (): Array<Record<string, any>> {
      const result = []
      if (this.startDate) {
        for (const n in [...Array(7).keys()]) {
          const dateClone = this.startDate.clone().add(n, 'days') as Moment
          result.push({
            instance: dateClone,
            date: dateClone.format(this.$helpers.dateFormat),
            dayShort: dateClone.format('dd'),
            dayNumber: dateClone.format('D')
          })
        }
      }

      return result
    },
    getCalendarRangeInfo (): string {
      const startDate = this.startDate.startOf('week').format('Do MMM')
      const endDate = this.endDate.format('Do MMM')
      return startDate + ' - ' + endDate
    }
  },
  methods: {
    addDuration (time: string) {
      return numberToTime(timeToNumber(time) + 60)
    },
    isDateButtonActive (date: Moment): boolean {
      return date.isSameOrAfter(this.now) || date.format(this.$helpers.dateFormat) === this.now.format(this.$helpers.dateFormat)
    }
  }
})
