import mixins from 'vue-typed-mixins'
import FlexibleBooking from '@/builder/sections/section/shared/mixins/FlexibleBooking'
import moment from 'moment'

export default mixins(FlexibleBooking).extend({
  data () {
    return {
      selectedTime: null,
      timeNotation: '24h',
      mergedTimeSpots: [
        '08:00',
        '09:00',
        '10:00',
        '15:00',
        '16:00'
      ],
      mergedTimeSpotObjects: [
        {
          time: '08:00',
          booked: false
        },
        {
          time: '09:00',
          booked: false
        },
        {
          time: '10:00',
          booked: true
        },
        {
          time: '11:00',
          booked: true
        },
        {
          time: '12:00',
          booked: false
        },
        {
          time: '13:00',
          booked: false
        }
      ],
      bookingCartSlots: [
        {
          key: 'first',
          date_start: moment().format(this.$helpers.dateFormat),
          time_start: '08:00',
          time_end: '09:00',
          price: 10000
        },
        {
          key: 'second',
          date_start: moment().format(this.$helpers.dateFormat),
          time_start: '09:00',
          time_end: '10:00',
          price: 10000
        }
      ]
    }
  },
  computed: {
    showServiceColumn (): boolean {
      return (typeof this.data.configuration.wb_show_service_column__checkbox__ === 'undefined' ||
        !!this.data.configuration.wb_show_service_column__checkbox__)
    },
    showBookedSlots (): boolean {
      return !!this.data.configuration.wb_show_booked_slots__checkbox__
    }
  },
  created () {
    this.selectedDate = new Date().toISOString().substr(0, 10)
  }
})
